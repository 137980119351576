body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}
body {
  overflow-x: hidden;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.mr-1{
  margin-right:8px
}
.m-t-22{
  margin-top:22px
}
.tile-report{
  padding: 20px 2px !important;
}

.tile-ul{
  margin: auto -18px !important;
}

.text-dark{
  color: #322929;
  font-size: 15px;
}

.export{
  width: 100%;
    margin: 0px;
}

.input-group-addons {
  padding: 3px 12px !important;
  font-size: 12px !important;
  font-weight: normal !important;
  line-height: 1 !important;
  color: #555555 !important;
  text-align: center !important;
  background-color: #eeeeee !important;
  border: 1px solid #ebebeb !important;
  border-radius: 3px !important;
}
.loaderBackground {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.m-r-0{
  margin-right:0px !important;
}
.page-size{
  padding: 9px 0px;
    margin: 0px -17px;
}
.faiconcat4{
  background-color: red;
  padding: 8px;
    color: white;
}
.faiconcat5{
  background-color: green;
  padding: 8px;
    color: white;
}
.css-26l3qy-menu{
  z-index: 999 !important
}
.radio-margin-right {
  margin-right: 10px;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100% !important;
}

.filter-right-align{
    text-align: right !important;
    padding-right:0px !important;
    margin-right: 0px !important;
    margin-bottom: 10px !important;
    margin-top: 33px !important;
}
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  width: 100% ;
}

.ck-editor__editable_inline p{
  height: 150px;
}

.panel-a{
  padding: 12px 0px !important;
}
.panel-b{
  padding-left: 8px !important;
}
.panel-c{
  padding-right:8px !important;
}

 #page-size{
  border-radius: 3px;
  line-height: 41px;
  outline: none;
  padding-left: 7px;
  margin-left: 10px;
  background: white;
 }
.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100% !important;
  border: 1px solid #ebebeb;
  height: 31px;
  padding: 6px 12px;
}
.faiconcat2 {
  color: white;
  font-size: 14px;
  background-color: #1a8fbf;
  padding: 5px;
  border-radius: 2px;
  transition: .5s;
  margin-right: 5px;
}
.location-search-input{
  display: block;
  width: 100%;
  height: 31px;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ebebeb;
  border-radius: 3px;
}

.tile-stats{
  height: 180px;
}

.star-mark{
  color:red;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 4px solid #070606;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  position: absolute;
  top: 15%;
  left: 50%;
  z-index: 9999;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.cirtAreaSelectBox {
  position: relative;
  box-sizing: border-box;
  width: 76% !important;
}
.tags {
  margin: 0px 8px 5px 8px;
}
.edit {
  cursor: pointer;
}

.errorClass {
  color: #cc2424;
  display: inline-block;
  margin-top: 5px;
}
.App-link {
  color: #61dafb;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.inner-routing-component {
  padding: 20px;
}

#root {
  height: 100%;
}

.saveInformationButton {
  position: absolute;
  top: 6.2%;
  left: 72%;
}

.table_j12 > tbody > tr > td{
  border-top: 1px solid #f56854 !important;
  color: #fff;
}
.h-scroll{
  overflow:scroll;
 
}
.scrollable-content{
  height: 100px;
    width: 100%;
    overflow-y: scroll;
}

.table_j12 > tbody > tr > td {
  border-top: none !important;
    color: #fff;
}

.cursor-pointer{
  cursor: pointer;
}
/* scrollbar style */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #373e4a; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #373e4a; 
}
/**
 *
 * Apply Here your Custom CSS
 *
*/

/* Switch Button Css */

.switcha {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 23px;
  }
  
  .switcha input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slidera {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slidera:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slidera {
    background-color: #2196F3;
  }
  
  input:focus + .slidera {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slidera:before {
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slidera.round {
    border-radius: 34px;
  }
  
  .slidera.round:before {
    border-radius: 50%;
  }

 /* Notification */

 .form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .6rem;
  margin-left: 0;
}
.form-check-label {
  margin-bottom: 5px;
  margin-right: 25px;
  margin-left: 5px;
}
.colpd{
  padding-top: 7px;
}

.overlay {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background: #0000007a;
  z-index: 100;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}
.imgeloder{
  border: 5px solid #f3f3f3;
  border-top: 4px solid #070606;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  position: inherit;
  top: 15%;
  left: 50%;
  z-index: 9999;
}
.minhight{
  min-height: 38px;
}

