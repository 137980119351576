/**
 *
 * Apply Here your Custom CSS
 *
*/

/* Switch Button Css */

.switcha {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 23px;
  }
  
  .switcha input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slidera {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slidera:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slidera {
    background-color: #2196F3;
  }
  
  input:focus + .slidera {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slidera:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slidera.round {
    border-radius: 34px;
  }
  
  .slidera.round:before {
    border-radius: 50%;
  }

 /* Notification */

 .form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .6rem;
  margin-left: 0;
}
.form-check-label {
  margin-bottom: 5px;
  margin-right: 25px;
  margin-left: 5px;
}
.colpd{
  padding-top: 7px;
}

.overlay {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background: #0000007a;
  z-index: 100;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}
.imgeloder{
  border: 5px solid #f3f3f3;
  border-top: 4px solid #070606;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  animation: spin 2s linear infinite;
  position: inherit;
  top: 15%;
  left: 50%;
  z-index: 9999;
}
.minhight{
  min-height: 38px;
}
